import React from 'react';
import ReactDOM from 'react-dom';

// styles
import 'bootstrap/scss/bootstrap.scss';
import 'assets/scss/paper-kit.scss';
// import 'assets/demo/demo.css';
// import 'assets/demo/react-demo.css';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalState from './context/GlobalState';

ReactDOM.render(
  <React.StrictMode>
    <GlobalState>
      <App />
    </GlobalState>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
