import React from "react";
import { SectionHeaders } from "../components/SectionHeader";
import Clients from "../components/Testimonials";
import Features from "../components/Features";
import ContactForm from "../components/Contact";
import WhoAreWe from "../components/WhoAreWe";
import Footer from "../components/Footer";
import StoryReference from "components/Story";

const Home = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SectionHeaders />
      <WhoAreWe />
      <Features />
      <StoryReference />
      <Clients />
      <ContactForm />
      <Footer />
    </>
  );
};

export default Home;
