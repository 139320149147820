/* eslint-disable react/prop-types */
import React from 'react';
import { Alert } from 'reactstrap';
export const Notification = (props) => {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
      <Alert color='success'>Melding sendt !</Alert>
    </div>
  );
};
