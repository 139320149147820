import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import emailjs from "emailjs-com";
import keys from "../config/emailkeys";
import { Notification } from "./Notification";
import GlobalContext from "../context/GlobalContext";

const ContactForm = () => {
  const context = React.useContext(GlobalContext);
  const loading = context.setLoading;
  const notification = context.setNotification;
  const showNotification = context._SHOWNOTIFICATION;
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  const sendMail = (e) => {
    loading(true);
    e.preventDefault();
    emailjs
      .sendForm(keys.SERVER_ID, keys.TEMPLATE_ID, e.target, keys.USER_ID)
      .then(
        () => {
          e.target.reset();
          Clear();
          loading(false);
          notification();
        },
        (error) => {
          Clear();
          loading(false);
          console.log(error.text);
        }
      );
  };

  const Clear = () => {
    setFirstname("");
    setLastname("");
    setEmail("");
    setMessage("");
  };

  return (
    <div className="section section-contactus cd-section" id="contact-us">
      <div className="contactus-1 section-image">
        <Container>
          {showNotification && <Notification />}
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card className="card-contact no-transition">
                <CardTitle className="text-center" tag="h3">
                  Kontakt oss
                </CardTitle>
                <Row>
                  <Col className="ml-auto mr-auto" md="10">
                    <Form
                      id="contact-form"
                      method="post"
                      role="form"
                      onSubmit={sendMail}
                    >
                      <CardBody>
                        <Row>
                          <Col md="6">
                            <FormGroup className="label-floating">
                              <label className="control-label">Fornavn:</label>
                              <Input
                                name="firstname"
                                placeholder="Fornavn"
                                type="text"
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup className="label-floating">
                              <label className="control-label">
                                Etternavn:
                              </label>
                              <Input
                                name="lastname"
                                placeholder="Etternavn"
                                type="text"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup className="label-floating">
                          <label className="control-label">Email:</label>
                          <Input
                            name="email"
                            placeholder="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                        <FormGroup className="label-floating">
                          <label className="control-label">Din beskjed:</label>
                          <Input
                            id="message"
                            name="message"
                            placeholder="...."
                            type="textarea"
                            rows="6"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          />
                        </FormGroup>
                        <Row>
                          <Col md="6">
                            <FormGroup check>
                              <Label check>
                                <Input defaultValue="" type="checkbox" />
                                I'm not a robot !
                                <span className="form-check-sign" />
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <Button
                              className="pull-right"
                              color="warning"
                              type="submit"
                            >
                              Send melding
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ContactForm;
