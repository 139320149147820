import React from "react";
import "./sass/style.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./views/Home";
import About from "./views/About";
import Contact from "./views/Contact";
import Employer from "./views/Employer";
import InfoNavbar from "components/InfoNavbar";
import ReferencesList from "views/ReferencesList";
import Employee from "views/Employee";
import Services from "views/OurServices";
import { LoadingSpinner } from "./components/LoadingSpinner";
import GlobalContext from "./context/GlobalContext";
import Vacancys from "views/Vacancys";

function App() {
  const GlobalState = React.useContext(GlobalContext);
  const loading = GlobalState._LOADING;

  return (
    <>
      <Router>
        <div className="App">
          {loading && <LoadingSpinner />}
          <InfoNavbar />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/ourservices">
              <Services />
            </Route>
            <Route path="/employer">
              <Employer />
            </Route>
            <Route path="/employee">
              <Employee />
            </Route>
            <Route path="/vacancys">
              <Vacancys />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/references">
              <ReferencesList />
            </Route>
          </Switch>
        </div>
      </Router>
    </>
  );
}

export default App;
