import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";
import { Link } from "react-router-dom";

// Images
import Image1 from "../assets/images/team3.jpg";
import Image2 from "../assets/images/sivilengineer.jpg";
import Image3 from "../assets/images/salmonfarm.jpg";

// core components
const items = [
  {
    src: "url(" + Image1 + ")",
    content: (
      <Container>
        <Row>
          <Col className="text-left" md="6">
            <h1 className="title">Har dere behov for nye medarbeidere?</h1>
            <h5>
              Vi bistår i hele eller deler av rekrutteringsprosessen, og har
              solid erfaring innen både offentlig og privat sektor. Vårt fokus
              er å skaffe den beste kompetansen og gi personlig oppfølging til
              bedrift og medarbeidere.
            </h5>
            <br />
            <div className="buttons">
              <Button
                className="btn-round"
                color="danger"
                onClick={(e) => e.preventDefault()}
                size="lg"
              >
                <Link className="custom-links" to="/contact">
                  Kontakt{" "}
                </Link>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "Fjelltur på Helgeland",
    caption: "Fjelltur på helgelandskysten",
  },
  {
    src: "url(" + Image2 + ")",
    content: (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h1 className="title">Kontakt oss i dag</h1>
            <h5>
              Er du på utkikk etter jobb, eller vil du utforske mulighetene
              dine? Vi vil gjerne snakke med deg! Prøv oss i dag - vi viser deg
              hva vakre Helgeland har å by på.
            </h5>
          </Col>
        </Row>
      </Container>
    ),
    altText: "Mo i Rana sentrum, Helgeland",
    caption: "Mo i Rana sentrum",
  },
  {
    src: "url(" + Image3 + ")",
    content: (
      <Container>
        <Row>
          <Col className="ml-auto text-right, header-text" md="7">
            <h2 className="title">Helgeland, et marked for alle!</h2>
            <h5>
              Helgeland har et rikt næringsliv innen havbruk, prosessindustri,
              handel, servicenæringer og offentlig sektor. Her finner du lokale
              og internasjonale aktører og jobb mulighetene er mange. Vi kan
              hjelpe deg.
            </h5>
          </Col>
        </Row>
      </Container>
    ),
    altText: "Helgeland næringsliv",
    caption: "Padling på Helgeland",
  },
];

export function SectionHeaders() {
  // carousel - header 3
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="header-3">
        <div className="page-carousel">
          <div className="filter" />
          <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {items.map((item) => {
              return (
                <CarouselItem
                  onExiting={onExiting}
                  onExited={onExited}
                  key={item.src}
                >
                  <div
                    className="page-header"
                    style={{
                      backgroundImage: item.src,
                    }}
                  >
                    <div className="filter" />
                    <div className="content-center">{item.content}</div>
                  </div>
                </CarouselItem>
              );
            })}
            <a
              className="left carousel-control carousel-control-prev"
              data-slide="prev"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                previous();
              }}
              role="button"
            >
              <span className="fa fa-angle-left" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="right carousel-control carousel-control-next"
              data-slide="next"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                next();
              }}
              role="button"
            >
              <span className="fa fa-angle-right" />
              <span className="sr-only">Next</span>
            </a>
          </Carousel>
        </div>
      </div>
    </>
  );
}
