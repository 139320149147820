import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import Woman from "../assets/images/woman.jpg";

const Information = () => {
  return (
    <>
      <Container className="ourServices">
        <Row className="justify-content-md-center">
          <Col className="h1-heading ml-auto mr-auto text-center" md={8}>
            <h1>Våre Tjenester</h1>
            {/* <br />
            <h3>Helgeland – 4 regionsenter</h3>
            <p>
              Mye av Helgeland er ett bo og arbeidsmarked. Bor du i Mosjøen
              eller Sandnessjøen er du i dagpendler avstand til 50 tusen
              mennesker. Avstanden mellom Mo i Rana og Mosjøen er en time og
              flere dagpendler. Dette gir mange muligheter for arbeidssøkende og
              for bedriftsetableringer. Vi kan hjelpe deg enten du søker arbeid
              eller du ønsker etablering.{" "}
            </p>
            <h4>Helgeland gir mulighet for et rikt og aktivt liv</h4>
            <p>
              Helgeland har tusenvis av øyer, hvite strender, lune viker, fjell
              og vidder som gir mulighet for et aktivt friluftsliv. Regionen har
              teater, sener, museer og aktive kunst miljø. Regionen er rik på
              opplevelsesbaserte næringer med restauranter, overnatting og
              tilrettelagte opplevelser.
            </p> */}
          </Col>
          <Col className="services ml-auto mr-auto text-center" md={12}>
            <h2 className="margin-text">Rekruttering</h2>
            <p>
              Vi bistår i hele eller deler av rekrutteringsprosessen. Vi kjenner
              Helgeland og bistår i tillegg til lokale bedrifter, større
              nasjonale selskap med den lokale kunnskap og nettverk. Offentlig
              sektor har ofte egne prosedyrer og egne avdelinger som ivaretar
              rekrutteringsprosesser. Vi brukes for å tilføre objektivitet og
              kvalitet i rekrutteringsprosesser. Ofte som bistand til våre
              kunders HR avdelinger. Bruk oss til å kvalitetssikre at prosessen
              blir god.
            </p>
          </Col>
          <Col className="services ml-auto mr-auto text-center" md={12}>
            <h2 className="margin-text">Bemanning</h2>
            <p>
              Educo er også et bemanningsbyrå. Vår spesialitet er
              administrative-, salg- og service stillinger. Lederutvelgelse er
              eksempel på hva vi bistår med.
            </p>
          </Col>
          <Col className="services ml-auto mr-auto text-center" md={12}>
            <h2 className="margin-text">Resurssenter for deg som søker jobb</h2>
            <p>
              Vi bistår arbeidssøkere med kobling mellom søker og arbeidsgiver.
              Vi har et nettverk av bedrifter og organisasjoner som trenger
              arbeidsressurser. Disse kan vi koble mot deg som arbeidssøker. Det
              kan være faste eller engasjementstillinger.
            </p>
          </Col>
          <Col className="services ml-auto mr-auto text-center" md={12}>
            <h2 className="margin-text">Kontakt</h2>
            <h6>For deg som er arbeidssøker</h6>
            <p>
              Er du arbeidssøker er du velkommen til å kontakte oss for en
              uformell samtale eller legge inn cv i vår portal. Vi behandler
              alle våre kontakter etter regler om GDPR. Derfor vår spesial
              portal. Alle cv’er slettes etter 3 måneder og alle behandles i
              fortrolighet etter regler om GDPR.
            </p>
          </Col>
          <Col className="services ml-auto mr-auto text-center" md={12}>
            <h2 className="margin-text">Kontakt </h2>
            <h6>For deg som er arbeidsgiver</h6>
            <p>
              Vi tilbyr tjenester innen rekruttering og bemanning. Ta gjerne
              kontakt for en uformell samtale. Sammen finner vi ut av om vi kan
              bistå og om det er grunnlag for et samarbeid.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Information;
