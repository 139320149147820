import React from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";

import BlueLogo from "../assets/images/Educo-Logo_standard_transparent_liten.png";

// core components
function WhiteNavbar() {
  const [bodyClick, setBodyClick] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = React.useState(0);
  const location = useLocation();

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", resizeWindow);
    return () => {
      window.removeEventListener("resize", resizeWindow);
      setBodyClick(false);
    };
  }, []);

  const CloseNav = () => {
    setBodyClick(false);
    document.documentElement.classList.toggle("nav-open");
  };

  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
          }}
        />
      ) : null}
      <Navbar className="fixed-top" expand="lg" id="navbar-main">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to="/" tag={Link}>
              <img
                className={`logo ${
                  !bodyClick ? "navBarLogoShow" : "navBarLogoHidden"
                }`}
                id="logo"
                src={BlueLogo}
                alt="logo"
              />
            </NavbarBrand>

            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(!bodyClick);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                {windowWidth < 992 && (
                  <NavbarBrand id="navbar-brand" to="/" tag={Link}>
                    <img
                      className={`logo ${
                        bodyClick ? "navBarLogoShow" : "navBarLogoHidden"
                      }`}
                      id="logo"
                      src={BlueLogo}
                      alt="logo"
                    />
                  </NavbarBrand>
                )}
              </NavItem>
              <NavItem active={location.pathname == "/"}>
                <NavLink
                  className="nav_link"
                  exact
                  to="/"
                  onClick={() => CloseNav()}
                >
                  Forside
                </NavLink>
              </NavItem>
              <NavItem active={location.pathname == "/about"}>
                <NavLink
                  className="nav_link"
                  to="/about"
                  isActive={() => location.pathname == "/about"}
                  onClick={() => CloseNav()}
                >
                  Om oss
                </NavLink>
              </NavItem>
              <NavItem active={location.pathname == "/ourservices"}>
                <NavLink
                  className="nav_link"
                  to="/ourservices"
                  isActive={() => location.pathname == "ourservices"}
                  onClick={() => CloseNav()}
                >
                  Våre tjenester
                </NavLink>
              </NavItem>
              {/* <NavItem active={location.pathname == "/employer"}>
                <NavLink
                  className="nav_link"
                  to="/employer"
                  isActive={() => location.pathname == "/employer"}
                  onClick={() => CloseNav()}
                >
                  For arbeidsgiver
                </NavLink>
              </NavItem>
              <NavItem active={location.pathname == "/employee"}>
                <NavLink
                  className="nav_link"
                  to="/employee"
                  isActive={() => location.pathname == "/employee"}
                  onClick={() => CloseNav()}
                >
                  For arbeidstaker
                </NavLink>
              </NavItem>  */}
              <NavItem active={location.pathname == "/vacancys"}>
                <NavLink
                  className="nav_link"
                  to="/vacancys"
                  isActive={() => location.pathname == "/vacancys"}
                  onClick={() => CloseNav()}
                >
                  Ledige stillinger
                </NavLink>
              </NavItem>
              <NavItem active={location.pathname == "/contact"}>
                <NavLink
                  className="nav_link"
                  to="/contact"
                  isActive={() => location.pathname == "/contact"}
                  onClick={() => CloseNav()}
                >
                  Kontakt
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default WhiteNavbar;
