import Loader from 'react-loader-spinner';

export const LoadingSpinner = () => {
  return (
    <div className='overlay'>
      <Loader
        className='loader'
        type='ThreeDots'
        color='rgba(0,0,240,.6)'
        height='100'
        width='100'
      />
    </div>
  );
};
