import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import BPLogo from "assets/images/Bedriftspartner_png1.png";

const Vacancy = () => {
  return (
    <>
      <Container className="ourServices">
        <Row className="justify-content-md-center">
          <Col className="h1-heading ml-right mr-auto text-start" md={5}>
            <h1>Ledige stillinger</h1>
            <br />
            <h3>Bedriftspartner</h3>

            <p>
              Bedriftspartner består av tre autoriserte regnskapsførerselskap
              som arbeider med regnskapsførsel, skatterådgivning, økonomistyring
              og bedriftsutvikling. Vi er lokalisert i Brønnøysund, Dønna,
              Mosjøen, Nesna og i Sandnessjøen, og er til sammen 24 medarbeidere
              med lang erfaring fra regnskap, revisjon og bedriftsutvikling. Vi
              har en svært spennende kundemasse og ønsker å være en leverandør
              som bidrar til at kundene lykkes. Vi jobber i et samspill med
              kundene, kompetente medarbeidere og en fremtidsrettet teknologi
              (se vår hjemmeside www.dinbedriftspartner.no). For å møte våre
              kunders – og markedets behov ønsker vi å forsterke våre team med
              en:
            </p>

            <h2>Autorisert regnskapsfører/regnskapsfører</h2>
            <br />
            <h6> Arbeidsoppgaver:</h6>
            <li>
              Du vil få ansvar for egen kundeportefølje og arbeide med løpende
              regnskapsførsel, årsoppgjør, lønn og rapportering. Som autorisert
              vil du også få oppdragsansvar. Du vil også være med å utvikle gode
              arbeidsmetoder i grensesnittet kunder, teamarbeid og teknologi.
            </li>
            <br />
            <h6>Vi søker medarbeider med</h6>
            <p>
              <li>Positive holdninger</li>
              <li>Høy arbeidskapasitet</li>
              <li>
                Høyskoleutdanning i økonomi og regnskap. Gjerne autorisasjon,
                men mangel på dette kan kompenseres med praksis og interesse for
                regnskap og økonomistyring.{" "}
              </li>
            </p>

            <p>
              Du kan enten være nyutdannet eller ha erfaring. Vi vil tilpasse
              stillingen etter de kvalifikasjonene som den «riktige» kandidaten
              har.
            </p>
          </Col>
          <Col className="h1-heading ml-right mr-auto text-start" md={5}>
            <div className="col-margin">
              <h6> Vi kan tilby:</h6>
              <li> Et godt og utfordrende arbeidsmiljø</li>
              <li>Moderne teknologiske arbeidsmetoder </li>
              <li>
                Faglig utvikling innenfor regnskap, skatt og økonomistyring{" "}
              </li>
              <li>
                Traineeprogram som fører frem til autorisasjon som
                regnskapsfører
              </li>
              <li> Mange spennende kunder</li>
              <li> Konkurransedyktige betingelser</li>
              <li> Fleksible arbeidsforhold</li>

              <p>
                Hvor du bor i vår region og hvor du vil jobbe, er ikke viktig.
                For den rette medarbeider tilpasser vi arbeidssted, også i en
                hybridløsning med kontor og hjem.
              </p>
              <p>
                Kontakt Jørn Clausen på tlf. 909 88 879, eller send en
                kortfattet søknad med CV til jc@dinbedriftspartner.no.{" "}
              </p>
              <Image className="logoImage" src={BPLogo} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Vacancy;
