import React from "react";
import { Image } from "react-bootstrap";

const TopHeader = (props) => {
  const dimensions = React.useRef();

  return (
    <>
      <div className="img-fluid" style={{ flex: 1 }} ref={dimensions}>
        <Image style={{ width: "100%" }} responsive src={props.HeaderImage} />
        {/* {props.heading && <h1 style={props.headingStyle}>{props.heading}</h1>} */}
      </div>
    </>
  );
};

export default TopHeader;
