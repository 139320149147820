import React from "react";
import TopHeader from "../components/Header";
import TeamMembers from "../components/Team";
import HeaderImageAbout from "../assets/images/handshake.jpg";
import HeaderImageMobile2 from "../assets/images/handshake2.jpg";

import ContactForm from "components/Contact";
import Footer from "components/Footer";

const About = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 600;

  React.useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <TopHeader
        className="img-fluid"
        content
        HeaderImage={width > breakpoint ? HeaderImageAbout : HeaderImageMobile2}
        heading="Kontakt oss"
        headingStyle={{
          color: "white",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      />
      <TeamMembers />
      <ContactForm />
      <Footer />
    </>
  );
};

export default About;
