import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

import Image1 from "../assets/images/JørnClausen.jpg";

import Image2 from "../assets/img/faces/PerInge1.jpg";

// core components

function TeamMembers() {
  return (
    <>
      <div className="section section-team cd-section" id="teams">
        <div className="team-2 section-image">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title-text">Om Educo</h2>
                <h5>
                  Educo Rekruttering ble stiftet i 2007 og har siden jobbet tett
                  med flere ulike bedrifter og medarbeidere på Helgeland og
                  resten av landet. Vi er et søsterselskap av Bedriftspartner
                  som er et autorisert regnskapsforetak som også jobber med
                  bedriftsutvikling. Educo Rekruttering er et nettverksselskap
                  som jobber tett med ressurspersoner i Sentrum Næringshage,
                  Helgeland Havbruksnettverk og Bedriftspartner selskapene, og
                  har gjennom disse et sterkt nettverk blant ressurspersoner og
                  bedrifter på Helgeland.
                </h5>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <CardImg top tag="div">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img alt="Jørn Clausen" className="img" src={Image1} />
                    </a>
                  </CardImg>
                  <CardBody>
                    <CardTitle tag="h4">Jørn Clausen</CardTitle>
                    <h6 className="card-category">Daglig leder - rådgiver</h6>
                    <p>
                      + 47 97657988 <br />
                      jc@dinbedriftspartner.no
                    </p>
                    <CardFooter>
                      <Button
                        className="btn-link btn-just-icon mr-1"
                        color="black"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-linkedin" />
                      </Button>
                      {/* <Button
                        className="btn-link btn-just-icon mr-1"
                        color="black"
                        href="https://www.linkedin.com/in/j%C3%B8rn-clausen-aa347b91/"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-facebook" />
                      </Button> */}
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <CardImg top tag="div">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img alt="..." className="img" src={Image2} />
                    </a>
                  </CardImg>
                  <CardBody>
                    <CardTitle tag="h4">Per Inge Dalheim</CardTitle>
                    <h6 className="card-category">Rekrutteringsrådgiver</h6>
                    <p>
                      + 47 48261398
                      <br />
                      pid@snhh.no
                    </p>
                    <CardFooter>
                      <Button
                        className="btn-link btn-just-icon mr-1"
                        color="black"
                        href="https://www.linkedin.com/in/per-inge-dalheim-7aba655a/"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-linkedin" />
                      </Button>
                      {/* <Button
                        className="btn-link btn-just-icon mr-1"
                        color="black"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-facebook" />
                      </Button> */}
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              {/* <Col md="4">
                <Card className="card-profile card-plain">
                  <CardImg top tag="div">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img alt="..." className="img" src={Image3} />
                    </a>
                  </CardImg>
                  <CardBody>
                    <CardTitle tag="h4">Sophie West</CardTitle>
                    <h6 className="card-category">Medarbeider</h6>
                    <p>
                      + 47 97657988 <br />
                      educo@educo.no
                    </p>

                    <CardFooter>
                      <Button
                        className="btn-link btn-just-icon mr-1"
                        color="black"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-dribbble" />
                      </Button>
                      <Button
                        className="btn-link btn-just-icon mr-1"
                        color="black"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-twitter" />
                      </Button>
                      <Button
                        className="btn-link btn-just-icon"
                        color="black"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fa fa-linkedin" />
                      </Button>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col> */}
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default TeamMembers;
