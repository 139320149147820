import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const WhoAreWe = () => {
  return (
    <>
      <div className="backgroundWho">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title-text">Hvem er vi?</h2>
              <h5 className="description-text">
                Educo rekruttering er et rekrutterings- og bemanningsbyrå på
                Helgeland med base i Mosjøen og lang erfaring og kompetanse
                innen ulike sektorer. Vi tilbyr også rådgivning og veiledning
                for studenter og innen ledelse, salg, markedsføring,
                rekruttering, strategi, økonomistyring og HR.
              </h5>
              {/* <h3 className="title">Helgeland - 4 regionsenter</h3>
              <h5 className="description">
                Mye av Helgeland er ett bo og arbeidsmarked. Bor du i Mosjøen
                eller Sandnessjøen er du i dagpendler avstand til 50 tusen
                mennesker. Avstanden mellom Mo i Rana og Mosjøen er en time og
                flere dagpendler. Dette gir mange muligheter for arbeidssøkende
                og for bedriftsetableringer. Vi kan hjelpe deg enten du søker
                arbeid eller du ønsker etablering.
              </h5> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default WhoAreWe;
