import React, { Component } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { faCentercode } from "@fortawesome/free-brands-svg-icons";

export class MapContainer extends Component {
  render() {
    return (
      <Map
        containerStyle={{
          height: this.props.height,
          maxWidth: this.props.width,
          position: "relative",
        }}
        google={this.props.google}
        zoom={14}
        initialCenter={{
          lat: 65.83719500071952,
          lng: 13.190147369965572,
        }}
      >
        <Marker
          onClick={(res) => console.log(res)}
          name={"Current location"}
          position={{
            lat: 65.83719500071952,
            lng: 13.190147369965572,
          }}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCtq-MeYSrkTN-ls0RXsKvkbvt15rSNHb0",
})(MapContainer);
