import React from "react";
import InfoNavbar from "../components/InfoNavbar";
import TopHeader from "../components/Header";
import References from "../components/References";
import ContactForm from "../components/Contact";
import Footer from "../components/Footer";

// Images
import HeaderImage1 from "../assets/images/fishfarm.jpg";
import HeaderImageMobile2 from "../assets/images/hands.jpg";

const ReferenceList = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 600;

  React.useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <InfoNavbar />
      <TopHeader
        className="img-fluid"
        content
        HeaderImage={width > breakpoint ? HeaderImage1 : HeaderImageMobile2}
        heading="Kontakt oss"
        headingStyle={{
          color: "white",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      />
      <References />
      <ContactForm />
      <Footer />
    </>
  );
};

export default ReferenceList;
