import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link } from 'react-router-dom';

import BlueLogo from '../assets/images/Educo-Logo_standard_liten.png';

const Footer = () => {
  return (
    <>
      <footer>
        <Container>
          <Row>
            <Col className='ml-auto mr-auto text-center' md={4}>
              <h3>Educo Rekruttering AS</h3>
              <div>
                <a href='https://www.facebook.com/Educorekruttering'>
                  <i className='fa fa-facebook-square fa-lg' />
                </a>

                <a href='https://www.linkedin.com/company/educo-rekruttering'>
                  <i className='fa fa-linkedin fa-lg' />
                </a>
              </div>
              <p>
                Strandgata 30,
                <br />
                8656 Mosjøen
                <br />
                Norge
              </p>
            </Col>
            <Col className='ml-auto mr-auto text-center' md={4}>
              <h3>Informasjon</h3>
              <p>
                <Link className='footer-links' to='/ourservices'>
                  Våre tjenester
                </Link>
                <br />
                <Link className='footer-links' to='/references'>
                  Referanseliste
                </Link>
                <br />

                {/* <Link className="footer-links" to="/vacancy">
                  Ledige stillinger
                </Link> */}
              </p>
              <p>
                Webdesign:{' '}
                <a className='flexboks-link' href='https://flexboks.com/'>
                  Flexboks AS
                </a>
              </p>
            </Col>
            <Col className='ml-auto mr-auto text-center' md={4}>
              <h3>Kontakt Educo</h3>
              <p>
                jc@dinbedriftspartner.no
                <br />
                Tel:
                <a className='footer-links' href='tel:+4790988879'>
                  +47 90 98 88 79
                </a>
              </p>
              <p>
                © 2021 Educo Rekruttering AS
                <br />
                Org nr: 992 100 206
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
