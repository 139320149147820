import React from "react-bootstrap";

// Images
import Member1 from "../assets/images/Elkem-logo.jpg";
import Member2 from "../assets/images/Helgelandkraft-logo.jpg";
import Member3 from "../assets/images/eika-logo.jpg";
import Member4 from "../assets/images/bm-logo.jpg";
import Member5 from "../assets/images/Mie1.jpg";

// reactstrap components
import { Card, CardBody, Container, Row, Col } from "reactstrap";

const Clients = () => {
  return (
    <>
      <div className="testimonials-3">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title-text">Dette sier kundene våre</h2>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto" md="3">
              <Card data-background="color" data-color="educo">
                <CardBody>
                  <div className="author">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar img-raised mr-2"
                        src={Member1}
                      />
                      <span>Elkem</span>
                    </a>
                  </div>
                  <span className="category-social pull-right">
                    <i className="fa fa-quote-right" />
                  </span>
                  <div className="clearfix" />
                  <p className="card-description">
                    "Vi opplever Educo Rekruttering som en dyktig og
                    profesjonell leverandør av bemanningstjenester."
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card data-background="color" data-color="blue2">
                <CardBody>
                  <div className="author">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar img-raised mr-2"
                        src={Member2}
                      />
                      <span>Helgelandskraft</span>
                    </a>
                  </div>
                  <span className="category-social pull-right">
                    <i className="fa fa-quote-right" />
                  </span>
                  <div className="clearfix" />
                  <p className="card-description">
                    "Vi synes samarbeidet har fungert utmerket, og vi har fått
                    en dyktig medarbeider som vi er godt fornøyd med!"
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col className="mr-auto" md="3">
              <Card data-background="color" data-color="educo">
                <CardBody>
                  <div className="author">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar img-raised mr-2"
                        src={Member3}
                      />
                      <span>Eika Forsikring</span>
                    </a>
                  </div>
                  <span className="category-social pull-right">
                    <i className="fa fa-quote-right" />
                  </span>
                  <div className="clearfix" />
                  <p className="card-description">
                    "Vi er veldig fornøyd med de tjenester Educo Rekruttering AS
                    har levert."
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto" md="4">
              <Card data-background="color" data-color="blue3">
                <CardBody>
                  <div className="author">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar img-raised mr-2"
                        src={Member4}
                      />
                      <span>BK Flokkmann</span>
                    </a>
                  </div>
                  <span className="category-social pull-right">
                    <i className="fa fa-quote-right" />
                  </span>
                  <div className="clearfix" />
                  <p className="card-description">
                    "Vi har benyttet oss av Educo Rekruttering sine tjenester
                    ved flere anledninger, og er veldig fornøyde med
                    resultatet."
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col className="mr-auto" md="6">
              <Card data-background="color" data-color="educo">
                <CardBody>
                  <div className="author">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar img-raised"
                        src={Member5}
                      />
                      <span>Mie Ramstad</span>
                    </a>
                  </div>
                  <span className="category-social pull-right">
                    <i className="fa fa-quote-right" />
                  </span>
                  <div className="clearfix" />
                  <p className="card-description">
                    "Dette var en flott mulighet for meg som kom rett fra
                    skolebenken, til å komme meg ut i jobb. Jeg har deltatt på
                    lokale samlinger for VA, og ikke minst deltatt på
                    kommunaltekniske messer, fagdager og fått møte andre som
                    også er trainee i samme firma. Det har vært en veldig fin
                    måte å tilegne seg kompetanse på, og jeg har trivdes godt
                    gjennom hele prosessen"
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Clients;
