/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import GlobalContext from './GlobalContext';

const GlobalState = (props) => {
  //  STATE
  const [_LOADING, _SET_LOADING] = useState(false);
  const [_SHOWNOTIFICATION, _SET_SHOWNOTIFICATION] = useState(false);

  const setLoading = (bool) => {
    _SET_LOADING(bool);
  };
  const setNotification = () => {
    _SET_SHOWNOTIFICATION(true);
    setTimeout(() => {
      _SET_SHOWNOTIFICATION(false);
    }, 3000);
  };

  return (
    <GlobalContext.Provider
      value={{
        _LOADING: _LOADING,
        _SHOWNOTIFICATION: _SHOWNOTIFICATION,
        setLoading: setLoading,
        setNotification: setNotification,
      }}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;
