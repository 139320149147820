import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

// Icons
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faHandsHelping } from "@fortawesome/free-solid-svg-icons";
// import { faSmile } from "@fortawesome/free-solid-svg-icons";
// import { faBuilding } from "@fortawesome/free-solid-svg-icons";

function Features() {
  return (
    <div className="section section-feature cd-section" id="features">
      <div className="features-1">
        <Container className="backgroundWho">
          <Row>
            <Col md="4">
              <div className="info">
                <div className="icon">
                  {/* <FontAwesomeIcon icon={faHandsHelping} /> */}
                </div>
                <div className="description-box">
                  <h4 className="info-title">Rekruttering</h4>
                  <p>
                    Vi bistår dere i hele eller deler av rekrutteringsprosessen,
                    og har solid erfaring med rekruttering innen alle
                    yrkeskategorier. <br />
                    <br />
                    Vi kjenner Helgeland og bistår i tillegg til lokale
                    bedrifter, større nasjonale selskap med den lokale kunnskap
                    og nettverk. Ta gjerne kontakt for en hyggelig og
                    uforpliktende prat.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="info">
                <div className="icon">
                  {/* <FontAwesomeIcon icon={faSmile} /> */}
                </div>
                <div className="description-box">
                  <h4 className="info-title">Bemanning</h4>
                  <p>
                    Du kan leie kandidater til vikariater, prosjekter og
                    midlertidige ansettelser. Educo Rekruttering AS står som
                    ansvarlig arbeidsgiver og sørger for blant annet lønn,
                    arbeidsgiveravgift, forsikring og sykelønn. Du faktureres
                    ikke for kandidatens eventuelle fravær. <br />
                    <br />
                    Vi har mange års erfaring med utleie av arbeidskraft i
                    Mosjøen og Sandnessjøen.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="info">
                <div className="icon">
                  {/* <FontAwesomeIcon icon={faBuilding} /> */}
                </div>
                <div className="description-box">
                  <h4 className="info-title">Trainee</h4>
                  <p>
                    Denne ordningen er spesielt rettet mot nyutdannede på
                    Bachelor eller Masternivå. I samarbeid med din eller flere
                    bedrifter vil en nyutdannet kunne få en trygg og god start
                    på sin yrkeskarriere. <br />
                    <br />
                    Varigheten er normalt ett til to år med gode utsikter til
                    fast jobb etterpå. Her vil personen delta på fagsamlinger og
                    treffe andre traineé kandidater. Lønn og rettigheter som en
                    ordinær ansatt.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Features;
