import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const References = () => {
  return (
    <>
      <Container className="ref-container" fluid>
        <h1 className="references-h1">Referanseliste rekrutteringsoppdrag</h1>

        <Row>
          <Col className="ml-auto mr-auto text-center" md="auto">
            <p>Alcoa Mosjøen - Business Analyst</p>
            <p>Alcoa Mosjøen - Controller</p>
            <p>Alcoa Mosjøen - Prosessingeniører</p>
            <p>Alcoa Mosjøen - Accounting Manager</p>
            <p> Alstahaug Kommune og Distriktssenter - Trainee (sosiolog)</p>
            <p>ASCO Norge, Kristiansund - Daglig Leder</p>
            <p>Bedriftspartner AS - Regnskapsmedarbeider</p>
            <p>BM Flokkmann - Driftsleder trelastbutikk</p>
            <p>BM Flokkmann - Produktansvarlig Maling </p>
            <p>BM Flokkmann - Sjåfør </p>
            <p>BM Flokkmann - Varemottaksansvarlig</p>
            <p>Christiania Glassmagasin Tromsø - Butikkmedarbeider</p>
            <p>Din Bedriftspartner AS - Regnskapsførere</p>
            <p>Eika Forsikring - Forsikringsrådgiver</p>
            <p>Elkem AS, Hovedkontoret - Regnskapsmedarbeidere</p>
            <p>Fluor - Byggleder</p>
            <p>Fluor - Elektroingeniør</p>
            <p>Fluor - Plass sjef</p>
            <p>Grong Sparebank - Kunderådgivere</p>
            <p>Grytåga Settefisk AS - Kokker</p>
            <p>Havnevesenet i Sandnessjøen - Havnedirektør</p>
          </Col>
          <Col className="ml-auto mr-auto text-center" md="auto">
            <p>Helgelandbase Eiendom - Administrerende Direktør</p>
            <p>Helgelandskreft - Regnskapsmedarbeidere</p>
            <p>Langjord Vedlikeholdsservice - Sandblåsere</p>
            <p>Mosjøen Glass og Fasade - Daglig Leder</p>
            <p>Mosjøen og Omegn Næringsselskap - Direktør</p>
            <p>Mosjøen og Omegn Næringsselskap KS - Daglig Leder </p>
            <p>Norgesvinduet Svenningdal - Kvalitetsleder</p>
            <p>Norpox Rehab AS - Sandblåsere</p>
            <p>Olsrud Rørleggermester - Butikkmedarbeider</p>
            <p>Paul Nygaard AS - Daglig Leder</p>
            <p>Pipetech AS - Area Manager</p>
            <p>Plast Sveis - Daglig Leder</p>
            <p>Sandnessjøen Engineering - Ingeniører</p>
            <p>Sandnessjøen Engineering - Trainee (ingeniør)</p>
            <p>Seløy Undervannsservice - Ingeniør/HMS-Kvalitetsleder</p>
            <p>Sentrum Næringshage - Kantinemedarbeidere/renholdere</p>
            <p>Sinus AS - Ingeniører</p>
            <p>Terra Forsikring AS - Forsikringskonsulent</p>
            <p>Trøndelag Stillas Bautas AS - Stillasbyggere</p>
            <p>Vefn Kommune - Rådmann</p>
            <p>Wang AS - Butikkmedarbeidere</p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default References;
