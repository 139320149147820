import React from "react";
import ContactForm from "./Contact";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import MapContainer from "components/Map";
import { faAlignCenter } from "@fortawesome/free-solid-svg-icons";

const ContactComponents = () => {
  const heightRef = React.useRef();
  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    setHeight(heightRef.current.clientHeight);
    setWidth(heightRef.current.clientWidth);
  });

  return (
    <>
      <Container>
        <Row>
          <Col lg={6} md={12}>
            <div ref={heightRef}>
              <ContactForm />
            </div>
          </Col>

          <Col lg={6} md={12}>
            <div className="outer">
              <div
                className="inner"
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  height: height - 100,
                }}
              >
                <MapContainer height={height - 100} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactComponents;
