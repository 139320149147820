import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
// reactstrap components
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

import Woman2 from "../assets/images/woman.jpg";
import Woman from "../assets/images/woman.jpg";

const StoryReference = () => {
  return (
    <>
      <Container fluid>
        <Row className="background-row">
          {/* <Col className="referenceCol1" lg={true}>
            <img className="referenceImage" src={Woman} />
            <img className="referenceImage2" src={Woman2} />
          </Col> */}
          <Col
            className="referenceCol2"
            style={{
              flex: 1,
              textAlign: "center",
              alignSelf: "center",
            }}
            lg={true}
          >
            <Image className="storyImage" src={Woman} roundedCircle />
            <h1 className="margin-h1">Mie Ramstad</h1>
            <h3>- En fin måte å tilegne seg kompetanse</h3>

            <p className="storyP">
              Mitt navn er Mie Ramstad, og jeg har nå vært trainee hos Educo
              Rekruttering AS i snart 3 år. Min bakgrunn er en bachelor som
              byggingeniør fra Høgskolen i Gjøvik. I det jeg startet med
              jobbsøkingen, ble jeg kontaktet av Educo Rekruttering AS. Jeg ble
              forespurt om jeg kunne tenke meg en stilling som trainee ved
              kommunalteknisk sektor i Sandnessjøen. Dette var en flott mulighet
              for meg som kom rett fra skolebenken, til å komme meg ut i jobb.
              <br /> <br />
              Jeg startet 1. mai i 2012. Utfordringene har vært mange, og blant
              annet bestått av prosjektering av vei, utarbeidelse av normer,
              ansvarlig søker, utarbeidelse av anbudsdokumenter og lignende. Jeg
              har fått delta på en rekke kurs for å få kompetanse om
              tegneprogrammer for vei og VA, jeg har deltatt på lokale samlinger
              for VA, og ikke minst deltatt på kommunaltekniske messer, fagdager
              og fått møte andre som også er trainee i samme firma. Det har vært
              en veldig fin måte å tilegne seg kompetanse på, og jeg har trivdes
              godt gjennom hele prosessen.
            </p>
            <br />
            <br />

            <Button
              className="btn-round"
              onClick={(e) => e.preventDefault()}
              size="lg"
            >
              <Link className="custom-links" to="/ourservices">
                Se våre tjenester{" "}
              </Link>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default StoryReference;
